import React from 'react';

const LandingPages = () => (
  <div className="text-center">
    <h1>
      LandingPages
    </h1>
  </div>
);

export default LandingPages
