import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';

const ThankYouPage = ({match}) => {
  const path = match.path;
  let title = 'សូមអរគុណចំពោះការចុះឈ្មោះ!';
  let description = 'សូមពិនិត្យមើលអ៊ីមែលរបស់អ្នកសម្រាប់ព័ត៌មានគណនីរបស់អ្នក។';
  if(path === '/forget-password/instruction-sent') {
    title = 'ភ្លេចលេខសម្ងាត់ដែលបានផ្ញើតាមអ៊ីមែល!';
    description = 'សូមពិនិត្យមើលអ៊ីមែលរបស់អ្នកសម្រាប់ការណែនាំកំណត់ពាក្យសម្ងាត់របស់អ្នកឡើងវិញ។';
  } else if (path === '/reset-password/changed') {
    title = 'ពាក្យសម្ងាត់ត្រូវបានធ្វើបច្ចុប្បន្នភាព!';
    description = 'ពាក្យសម្ងាត់របស់អ្នកត្រូវបានធ្វើបច្ចុប្បន្នភាព។';
  }

  return (
    <div className="text-center">
      <p className="login-card-description font-weight-bold mb-1">{title}</p>
      <p className="login-card-footer-text mb-2">{description}</p>
      <p className="login-card-footer-text mb-0">ត្រលប់ទៅ
        <Link to='/signin' className="register-link"> ចូល!</Link>
      </p>
    </div>
  );
}


export default withRouter(ThankYouPage);
